//contact
.contact {
  .left {
    padding-right: 60px;
    ul {
      @include flex(center, none, none);
      margin-top: $xll;
    }
    .icon {
      width: 50px;
      height: 50px;
      border: 1px solid rgba($white, 0.2);
      border-radius: 10px 0 10px 0;
      @include flex(center, center, none);
    }
    .box {
      margin-bottom: $xll;
      .icons {
        color: $green;
      }
      h3 {
        padding: $xl-2 0;
      }
      span {
        opacity: 0.5;
      }
    }
  }
  .right {
    border-radius: 50px 0 50px 0;
    padding: $xll;
    // background-color: #171e20;
    form {
      margin-top: $xll;
    }
    .inputs {
      margin-bottom: $xl-6;
      span {
        text-transform: uppercase;
      }
      textarea,
      input {
        width: 100%;
        border: 2px solid rgba($dark, 0.6);;
        padding: 15px;
        margin-top: $sm;
        color: $dark;
      }
    }
    button {
      width: 100%;
      padding: 18px;
    }
  }
}

//single page
.post-details {
  .heading-title {
    .desc {
      text-align: left;
    }
  }
}

@media screen and (max-width: 965px) {
  .banner {
    .container {
      @include flex(center, none, column);
      button {
        margin-top: 50px;
      }
      h1 {
        font-size: 30px;
      }
    }
  }

  .blog-card {
    .card {
      @include flex(none, none, column);
      .card-img {
        width: 100%;
        height: 300px;
      }
      .card-details {
        width: 100%;
        padding: 0;
      }
    }
  }
  .w-30,
  .w-40,
  .w-50,
  .w-60,
  .w-70 {
    width: 100%;
    margin: 0;
  }
  .agency {
    .flex,
    .flex1 {
      flex-direction: column;
    }
    .grid-3 {
      @include grid(repeat(3, 1fr), 50px);
    }
  }

  .contact {
    .content {
      .left {
        text-align: center;
        margin-bottom: 50px;
        ul {
          display: flex;
          justify-content: center;
          align-items: center;
          li {
            margin: 0 20px;
          }
        }
      }
      flex-direction: column;
    }
  }
}
